<template>
	<div class="certificateQueryPage">
		<BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
		<div class="module1">
			<CourseTitle :title="证书查询"> </CourseTitle>
			<div class="content certificateQuery_div display">
				<div class="list flex-center column">
					<img :src="formData.golgaCertificateQueryIconUrl" alt="" />
					<div class="name">{{ formData.golgaCertificateQueryName }}</div>
					<div class="btn display pointer" @click="viewBtn(1)">立即查询</div>
				</div>
				<div class="list flex-center column">
					<img :src="formData.countryCertificateQueryIconUrl" alt="" />
					<div class="name">{{ formData.countryCertificateQueryName }}</div>
					<div class="btn display pointer" @click="viewBtn(2)">立即查询</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from './components/courseTitle.vue'
export default {
	components: {
		BannerImg,
		CourseTitle,
	},
	data() {
		return {
			formData: {},
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			this.api.certificateQueryPageApi().then((res) => {
				console.log(res)
				this.formData = res.data
			})
		},
		viewBtn(type) {
			if (type == 1) {
				this.$router.push({
					path: '/homes/certificateQuery',
					query: {
						bannerImgUrl: this.formData.bannerImgUrl,
					},
				})
			} else {
				// let pathInfo = this.$router.resolve({
				// 	path: this.formData.countryCertificateQueryLink,
				// })
				window.open(this.formData.countryCertificateQueryLink, '_blank')
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.certificateQueryPage {
	.module1 {
		height: 662px;
		background: #fafafa;
		.certificateQuery_div {
			margin-top: 40px;
			.list {
				width: 550px;
				height: 380px;
				background: #ffffff;
				border: 2px solid #054d9d;
				border-radius: 5px;
				margin: 0px 27px;
				img {
					width: 120px;
					height: 120px;
					margin-top: 48px;
				}
				.name {
					font-size: 24px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 24px;
					margin-top: 50px;
				}
				.btn {
					width: 160px;
					height: 48px;
					background: #ff881e;
					border-radius: 5px;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ffffff;
					margin-top: 26px;
				}
			}
		}
	}
}
</style>